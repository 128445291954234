import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router';
import { logout } from '@/util/authentication';
import { me } from '@/api/index';
import { isNoNeedLogins } from '@/routes/index';
import { Btn } from '@/components/basic/index';
import style from './Layout.scss';
import { isGood } from '@/util/company';

const { company, functions, reportUsageIds, reportUsageDomains } = window.env;

const Layout = ({ children }) => {
  const history = useHistory();
  const noNeedLogin = isNoNeedLogins(history.location.pathname);
  const [canUseReport, setCanUseReport] = useState(false);

  useEffect(() => {
    (async () => {
      if (noNeedLogin) {
        return;
      }
      const iam = await me();
      if (!iam) {
        doLogout();
      }

      if (iam === null) {
        return;
      }

      if (isGood()) {
        setCanUseReport(kGoodCanUseReport(iam));
      } else {
        setCanUseReport(functions.includes('report'));
      }
    })();
  }, []);

  /**
   * グッド社の場合、以下のいずれかの条件を満たしたご家庭のみ指導報告書を表示する
   * 1。石川県でかつ指導中
   * 2。特定のID
   * 3。メアドが特定のドメインで終わる
   * @param iam ご家庭情報
   * @returns {boolean} true:表示する false:表示させない
   */
  const kGoodCanUseReport = (iam) => {
    if (['石川県'].includes(iam.prefecture) && ['指導中'].includes(iam.status)) {
      return true;
    }
    if (reportUsageIds.includes(iam.id)) {
      return true;
    }
    if (
      _.filter(reportUsageDomains, (d) => {
        return iam.mail.endsWith(d);
      }).length > 0
    ) {
      return true;
    }
    return false;
  };

  const doLogout = (e) => {
    logout();
    document.location.href = '/login';
  };
  const moveTo = (to) => {
    history.push(to);
  };
  return (
    <>
      <Toaster />
      <Navbar className={`me-auto ${style.topBar}`}>
        <Nav className="me-auto">
          <NavItem className={style.brand}>{company.brandName}</NavItem>
        </Nav>
        <Nav className="justify-content-end">
          {!noNeedLogin && (
            <div className={`btn-group ${style.menuPanel}`}>
              <Btn
                isCustom={true}
                className={style.dropdownBtn}
                width="35px"
                height="35px"
                dataToggle="dropdown"
                ariaHaspopup="true"
                ariaExpanded="false"
                circle
                prefixIcon="faBars"
              />
              <div className={`dropdown-menu dropdown-menu-right ${style.dropDownPanel}`}>
                {canUseReport && (
                  <button className="dropdown-item" type="button" onClick={() => moveTo('/reportTop')}>
                    指導報告書
                  </button>
                )}
                {functions.includes('contract') && (
                  <button className="dropdown-item" type="button" onClick={() => moveTo('/')}>
                    ご契約内容
                  </button>
                )}
                <button className="dropdown-item" type="button" onClick={doLogout}>
                  ログアウト
                </button>
              </div>
            </div>
          )}
        </Nav>
      </Navbar>
      <div className="contents">
        <section className={style.pageContainer}>
          <main>{children}</main>
        </section>
      </div>
    </>
  );
};

export default Layout;
